<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="话题标题"
        :rules="[
          { required: true, message: '请输入话题标题', trigger: 'blur' },
        ]"
        prop="topicTitle"
      >
        <v-input
          placeholder="请输入话题标题"
          :width="width"
          v-model="form.topicTitle"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="副标题"
        :rules="[{ required: false, message: '请输入副标题', trigger: 'blur' }]"
        prop="nowStatus"
      >
        <v-input
          placeholder="请输入副标题"
          :width="width"
          :maxlength="12"
          v-model="form.nowStatus"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="话题封面"
        :rules="[
          {
            required: true,
            message: '请上传话题封面图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="topicPicture"
      >
        <v-upload
          :limit="1"
          :imgUrls.sync="form.topicPicture"
          tips="建议上传正方形图片"
        />
      </el-form-item>

      <el-form-item
        label="关闭点赞"
        :rules="[
          { required: true, message: '请选择是否关闭点赞', trigger: 'change' },
        ]"
        prop="closePraise"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closePraise"
        />
      </el-form-item>

      <el-form-item
        label="关闭评论"
        :rules="[
          { required: true, message: '请选择是否关闭评论', trigger: 'change' },
        ]"
        prop="closeComment"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closeComment"
        />
      </el-form-item>

      <el-form-item
        label="是否置顶"
        :rules="[
          { required: true, message: '请选择是否置顶', trigger: 'blur' },
        ]"
        prop="isTop"
      >
        <v-select :width="width" :options="isTopOps" v-model="form.isTop" />
      </el-form-item>

      <el-form-item
        v-if="form.isTop === 1"
        label="置顶排序"
        :rules="[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="isTopSort"
      >
        <v-input
          placeholder="请输入置顶排序值"
          v-model="form.isTopSort"
          min="1"
          :width="width"
          type="number"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="话题内容"
        :rules="[
          { required: true, message: '请输入话题内容', trigger: 'blur' },
        ]"
        prop="topicContent"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.topicContent" />
        </div>
      </el-form-item>

      <el-form-item
        label="发布状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="status"
      >
        <v-select :width="width" :options="statusOps" v-model="form.status" />
      </el-form-item>

      <el-form-item
        label="备注"
        :rules="[{ required: false, message: '请输入备注', trigger: 'blur' }]"
        prop="remark"
      >
        <v-input
          placeholder="备注"
          :width="width"
          v-model="form.remark"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  addTopicURL,
  updateTopicURL,
  getTalentUserListURL,
  getTopicInfoByIdURL,
} from "./api.js";
import { statusMap, setStatusOps, isTopMap, setIsTopOps } from "./map.js";

export default {
  name: "partnerForm",
  data() {
    return {
      width: 220,
      communityList: [],
      // talentTypeOps: [],
      // talentTypeMap: {},
      // talentUserOps: [],
      // talentUserMap: {},
      // isTopSortOps: [],
      isTopOps: setIsTopOps(),
      isTopMap,
      statusOps: setStatusOps(),
      statusMap,
      submitConfig: {
        queryUrl: getTopicInfoByIdURL,
        submitUrl: "",
      },
      form: {
        id: null,
        topicType: 2,
        optUser: undefined, // 用户id
        communityId: undefined, // 园区id
        talentUserId: "",
        isTop: 0, //是否置顶
        isTopSort: undefined, // 是否置顶排序
        closeComment: 0, //关闭点赞
        closePraise: 0, //关闭评论
        topicTitle: "", // 话题标题
        topicContent: "", // 富文本内容
        nowStatus: "",
        remark: "", // 备注
        status: undefined,
        topicPicture: "", // 话题封面
        subPicture: "",
        // adminId: null,
      },
      communityId: null,
      onOff: true, // 是否进入编辑
    };
  },
  created() {
    const { id } = this.$route.query;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ id });
      });
    }
    this.submitConfig.submitUrl =
      id !== undefined ? updateTopicURL : addTopicURL;
    this.onOff = id !== undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    changetalentType(value) {
      console.log(value, 89);
      this.form.talentUserId = "";
      this.getTalentUserList(value);
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      if (this.form.topicPicture) {
        this.form.subPicture = this.form.topicPicture;
      }
      this.onOff = false;
    },
    submitBefore() {
      if (!this.form.topicPicture || this.form.topicPicture.length == 0) {
        this.$message.error("请上传商品封面图片");
        return;
      }

      if (!this.form.optUser) {
        this.form.optUser = this.$store.state.app.userInfo.userId;
      }

      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
